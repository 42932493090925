<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="isAdd ? '新增调价单' : '编辑调价单'" @closed="form = null" width="1200px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="98px" label-suffix=":" v-if="form">
      <div class="h">
        <el-form-item label="流水号" prop="orderNo">
          <el-input v-model="form.orderNo" maxlength="20" disabled placeholder="系统自动生成" />
        </el-form-item>
        <el-form-item label="调价名称">
          <el-input v-model="form.name" maxlength="100" placeholder="调价名称，最大50字符" :readonly="form.enabled===true"/>
        </el-form-item>
      </div>
      <el-form-item v-if="form.enabled===false">
        <!-- <sku-selector button-text="选择商品" button-icon="el-icon-plus" @submit="addGoods" /> -->
        <sku-selector ref="goodsSel" button-text="选择商品" request-url="api/goods/sku" multiple :exclude-keys="excludeSkusByCondition" :request-params="{sort:'erpCode,asc'}" :show-details="false" @submit="handleGoodsSelect" />
      </el-form-item>
      <el-form-item>
        <el-table v-loading="loading" row-key="id" :data="form.subList" size="small" style="width: 100%;" empty-text="没有商品数据" >
          <el-table-column label="ERP编码" prop="erpCode" width="140" />
          <el-table-column label="名称" prop="name" min-width="150" show-overflow-tooltip/>
          <el-table-column label="规格" prop="specs" min-width="100" :formatter="$goodsSpecConvert" show-overflow-tooltip/>
          <el-table-column label="统一批发价" width="140">
             <template slot-scope="scope">
              <!-- <el-input type="number" v-model.trim="scope.row.goodsPrice" :readonly="form.enabled===true"/> -->
              <price-input v-model="scope.row.goodsPrice" :min="0.01" :max="999999999" :step-ratio="100"  />
            </template>
          </el-table-column>
          <el-table-column label="起订量" prop="modMin" width="100">
            <template slot-scope="scope">
              <el-input type="number" v-model.trim="scope.row.modMin" :readonly="form.enabled===true"/>
            </template>
          </el-table-column>
          <el-table-column label="开始时间" prop="beginTime" width="175">
            <template slot-scope="scope">
              <el-date-picker type="datetime" value-format="timestamp" v-model="scope.row.beginTime" style="width:165px" default-time="00:00:00" :readonly="form.enabled===true"/>
            </template>
          </el-table-column>
          <el-table-column label="结束时间" prop="endTime" width="175">
            <template slot-scope="scope">
              <el-date-picker type="datetime" value-format="timestamp" v-model="scope.row.endTime" style="width:165px" default-time="23:59:59" :readonly="form.enabled===true"/>
            </template>
          </el-table-column>
        <el-table-column width="50" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-popover :ref="scope.row._id" placement="top" v-if="form.enabled===false">
            <p>确定要删除该商品吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row._id].doClose()">取消</el-button>
              <el-button type="primary" size="mini" @click="doDelete(scope.row)" >确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="dialog = false">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit" v-if="form && form.enabled===false">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit ,get } from "@/api/priceOrder";
import skuSelector from "@/views/assembly/skuSelect";
export default {
  components: { skuSelector },
  props: {
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      enable:true,
      loading: false,
      searching: false,
      dialog: false,
      visible: false,
      form: {subList: []},      
      rules: {
        name: [{ required: true, message: "请输入调价名称", trigger: "blur" }],
      },
    };
  },
  computed:{
    excludeSkusByCondition() {      
      let os = [];
      (this.form.subList || []).forEach(item => {
          os.push(item.goodsId);
        });
        return os;
    }
  },
  methods: {
    handleGoodsSelect(res){
      let beg = new Date();
      beg.setHours(0,0,0);
      let end = new Date();
      end.setFullYear(end.getFullYear()+5);
      end.setHours(23,59,59);
      res.forEach(o => {
            this.form.subList.push({
              _id: this.$uuid(),
              goodsId: o.id,
              goodsType: "sku",
              spuId: o.spuId,
              specs: o.specs,
              name: o.name,
              code: o.code,
              erpCode: o.erpCode,
              modMin:1,
              beginTime:beg.getTime(),
              endTime:end.getTime()
            });
          });
    },
    doDelete(data){
      //this.form.subList.splice
      let inx = (this.form.subList || []).indexOf(data);
      if (inx >= 0) this.form.subList.splice(inx, 1);
    },
    doSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.isAdd) {
            this.doAdd();
          } else this.doEdit();
        }
      });
    },
    doAdd() {
      
      add(this.form)
        .then((res) => {
          this.$notify({
            title: "添加成功",
            type: "success",
            duration: 2500,
          });
          this.loading = false;
          this.dialog = false;
          this.$parent.init();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    doEdit() {
      edit(this.form)
        .then((res) => {
          this.$notify({
            title: "编辑成功",
            type: "success",
            duration: 2500,
          });
          this.loading = false;
          this.dialog = false;
          this.$parent.init();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    resetForm(form,readonly) {
      this.form = form || {
        name: "",
        orderNo: "",
        subList:[],
        enabled:false,

      };      
      this.dialog = true;
      if(form && form.id){
        this.form.subList=[];
        this.loading = true;
        get(form.id).then(res=>{          
          this.form.subList = res.subList;
          this.loading = false;
        }).catch(err=>{
          this.loading = false;
        })
      }
    },
  },
};
</script>
