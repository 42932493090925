<template>
  <div class="app-container v">
    <div class="head-container">
      <div style="display: inline-block;margin: 0px 2px;">
        <el-input v-model="query.key" clearable placeholder="输入流水号/名称搜索" prefix-icon="el-icon-search" style="width: 230px;" class="filter-item" @keypress.enter.native="toQuery" />
        <el-select v-model="query.status" filterable clearable placeholder="状态" class="filter-item" style="width: 120px" @change="toQuery">
          <el-option v-for="(v,k) in status" :key="k" :label="v" :value="k" />
        </el-select>
        <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
        <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
      </div>
    </div>

    <el-table v-loading="loading" :data="data" size="small" height="200px">
      <el-table-column prop="orderNo" label="流水号" />
      <el-table-column prop="name" label="调价名称" />
      <el-table-column prop="createBy" label="创建人" />
      <el-table-column :show-overflow-tooltip="true" label="创建时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createAt) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.enabled">已生效</el-tag>
          <el-tag type="info" v-else>未生效</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="120" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <div class="row-commands" v-if="scope.row.enabled===false">
            <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
            <el-popover :ref="scope.row.id" placement="top">
              <p>确定要删除该商品吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button type="primary" size="mini" @click="doDelete(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
            </el-popover>

            <el-button :loading="enableLoading" size="mini" type="text" @click="enabled(scope.row.id)">生效</el-button>
          </div>
          <div class="row-commands" v-else>
            <el-button size="mini" type="text" @click="view(scope.row)">查看</el-button>
          </div>
        </div>
        <!-- <div class="row-commands" slot-scope="scope" v-if="scope.row.enabled===true">
          <el-button size="mini" type="text" @click="view(scope.row)">查看</el-button>
        </div>-->
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <eForm ref="form" :is-add="isAdd" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { del, enable } from "@/api/priceOrder";
import { parseTime } from "@/utils/index";
import eForm from "./form";
import { mapGetters } from "vuex";

export default {
  name: "priceOrder",
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      status: { true: "已生效", false: "未生效" },
      isAdd: false,
      delLoading: false,
      enableLoading: false,
      downloadLoading: false,
      query: {},
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.init();
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = "api/distributorPriceOrder";
      // this.params = {
      //   sort: "orderNo,desc",
      // };
      let query = JSON.parse(JSON.stringify(this.query));
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: "orderNo,desc" },
        query
      );
      return true;
    },

    enabled(id) {
      this.$confirm("确定要使该调价单生效,生效后不允许修改/删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.enableLoading = true;
          enable(id)
            .then((_) => {
              this.init();
              this.$notify({
                title: "操作成功",
                type: "success",
                duration: 2500,
              });
              this.enableLoading = false;
            })
            .catch((_) => {
              this.enableLoading = false;
            });
        })
        .catch(() => {});
    },
    doDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
    add() {
      this.isAdd = true;
      this.$refs.form.resetForm();
    },

    view(data) {
      this.$refs.form && this.$refs.form.resetForm(data);
    },
    edit(data) {
      this.isAdd = false;
      let fd = JSON.parse(JSON.stringify(data));
      //fd.subList = [];
      this.$refs.form && this.$refs.form.resetForm(fd);
    },
  },
};
</script>